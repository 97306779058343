import Vue from 'vue'
import App from './App.vue'
import WotDesign from 'wot-design'
import VueCookies from 'vue-cookies';
import router from './router';
import 'wot-design/lib/theme-default/index.css'
import * as echarts from 'echarts';

router.beforeEach(function (to, from, next) {
    window.scrollTo(0, 0);
    // if (to.path == '/login') {
    //     sessionStorage.removeItem('appkey');
    //     sessionStorage.clear();
    //     location.href = "/ehgiga/index.html#/login";
    // }
    let appkey = sessionStorage.getItem('appkey');
    console.log(appkey);
    console.log(to.path);
    if (!appkey && to.path != '/login') {
        location.href = "/#/login";
        if (to.path == '/') {
            location.reload();
        }
    } else {
        next()
    }
});

Vue.use(WotDesign)
Vue.use(VueCookies)
Vue.use(router);

Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [{
        path: '/home',
        name: 'home',
        component: () => import('./app/AppHome.vue')
    }, {
        path: '/login',
        name: 'login',
        component: () => import('./app/LoginSys.vue')
    }, {
        path: '/applist',
        name: 'applist',
        component: () => import('./app/AppList.vue')
    }, {
        path: '/productlist',
        name: 'productlist',
        component: () => import('./app/Product/ProductList.vue')
    }, {
        path: '/product',
        name: 'product',
        component: () => import('./app/Product/ProductDetail.vue')
    }, {
        path: '/purchaselist',
        name: 'purchaselist',
        component: () => import('./app/Purchase/PurchaseList.vue')
    }, {
        path: '/purchase',
        name: 'purchase',
        component: () => import('./app/Purchase/PurchaseDetail.vue')
    }, {
        path: '/publiccustom',
        name: 'publiccustom',
        component: () => import('./app/Custom/PublicCustomList.vue')
    }, {
        path: '/mycustom',
        name: 'mycustom',
        component: () => import('./app/Custom/MyCustomList.vue')
    }, {
        path: '/custom',
        name: 'custom',
        component: () => import('./app/Custom/CustomDetail.vue')
    }, {
        path: '/factorylist',
        name: 'factorylist',
        component: () => import('./app/Factory/FactoryList.vue')
    }, {
        path: '/factory',
        name: 'factory',
        component: () => import('./app/Factory/FactoryDetail.vue')
    }, {
        path: '/productsamplelist',
        name: 'productsamplelist',
        component: () => import('./app/SampleProduct/SampleProductList.vue')
    }, {
        path: '/sampleproduct',
        name: 'sampleproduct',
        component: () => import('./app/SampleProduct/SampleProductDetail.vue')
    }, {
        path: '/quotationlist',
        name: 'quotationlist',
        component: () => import('./app/Quotation/QuotationList.vue')
    }, {
        path: '/quotation',
        name: 'quotation',
        component: () => import('./app/Quotation/QuotationDetail.vue')
    }, {
        path: '/shipmentlist',
        name: 'shipmentlist',
        component: () => import('./app/Shipment/ShipmentList.vue')
    }, {
        path: '/shipment',
        name: 'shipment',
        component: () => import('./app/Shipment/ShipmentDetail.vue')
    }, {
        path: '/putinstoragelist',
        name: 'putinstoragelist',
        component: () => import('./app/PutInStorage/PutInStorageList.vue')
    }, {
        path: '/instorage',
        name: 'instorage',
        component: () => import('./app/PutInStorage/PutInStorageDetail.vue')
    }, {
        path: '/orderlist',
        name: 'orderlist',
        component: () => import('./app/Order/OrderList.vue')
    }, {
        path: '/order',
        name: 'order',
        component: () => import('./app/Order/OrderDetail.vue')
    }, {
        path: '/claimsettlementlist',
        name: 'claimsettlementlist',
        component: () => import('./app/ClaimSettlement/ClaimSettlementList.vue')
    }, {
        path: '/claimsettlement',
        name: 'claimsettlement',
        component: () => import('./app/ClaimSettlement/ClaimSettlementDetail.vue')
    }, {
        path: '/inspectionlist',
        name: 'inspectionlist',
        component: () => import('./app/PurchaseInspection/InspectionList.vue')
    }, {
        path: '/inspection',
        name: 'inspection',
        component: () => import('./app/PurchaseInspection/InspectionDetail.vue')
    }, {
        path: '/factoryedit',
        name: 'factoryedit',
        component: () => import('./app/Factory/FactoryEditDetail.vue')
    }, {
        path: '/expensepaylist',
        name: 'expensepaylist',
        component: () => import('./app/Expensepay/ExpensepayList.vue')
    }, {
        path: '/expensepay',
        name: 'expensepay',
        component: () => import('./app/Expensepay/ExpensepayDetail.vue')
    }, {
        path: '/collectionaccountlist',
        name: 'collectionaccountlist',
        component: () => import('./app/CollectionAccount/CollectionAccountList.vue')
    }, {
        path: '/collectionaccount',
        name: 'collectionaccount',
        component: () => import('./app/CollectionAccount/CollectionAccountDetail.vue')
    }]
})